<template>
  <div class="container">
    <div class="banner">
      <img src="@/assets/img/icon972.png" class="w100 block" />
    </div>
    <van-tabs @click="changeTab">
      <van-tab
        v-for="(unit, index) in cateLogList"
        :key="index"
        :title="unit.catalogFirstName"
      >
        <div class="px10">
          <section
            class="mt10 p10 row border lump"
            @click="
              $router.push({
                path: '/prefecture/download',
                query: { isUserPad: true },
              })
            "
            v-for="(inner, innerdex) in list"
            :key="'list' + innerdex"
          >
            <img :src="inner.photoPath" alt="" class="iconImg" />
            <aside class="flex1 ml10">
              <div class="between">
                <span>{{ inner.techName }}</span>
                <span class="font12" v-if="inner.isAllow">
                  <span class="appoint-left">最近可约</span>
                  <span class="appoint-right">{{ inner.recentlyTime }}</span>
                </span>
                <span v-else></span>
              </div>
              <div class="c9 font12 mt5">
                <span class="relative top1">
                  <van-rate
                    :value="1"
                    count="1"
                    size="14"
                    disabled-color="rgba(255, 140, 66, 1)"
                    disabled
                  />
                </span>
                <span class="ml3">{{ Number(inner.score).toFixed(1) }}分</span>
                <span class="mx10 line">|</span>
                <span>
                  服务
                  <span class="orange"
                    >{{
                      inner.orderNum > 10000 ? "1w+" : inner.orderNum
                    }} </span
                  >单
                </span>
                <span class="mx10 line">|</span>
                <span>
                  {{
                    geoFlag
                      ? `${inner.distance}米${(inner.diffTime / 60).toFixed(
                          0
                        )}分钟`
                      : inner.districtName
                  }}
                </span>
              </div>
              <div class="font12 my10">
                <span class="tips" v-if="inner.cut">有满减</span>
                <!-- <span class="tips" v-if="inner.cut">有秒杀</span> -->
                <span class="tips" v-if="inner.newProject">有新品</span>
                <span class="tips" v-if="inner.discount">有折扣</span>
              </div>
              <div class="row cf price-lump">
                <div class="left tc">
                  <span class="font16">￥{{ inner.price }}</span>
                  <span class="font12 ml4">起</span>
                </div>
                <div class="right">
                  <img
                    src="@/assets/img/icon974.png"
                    class="relative left5 block"
                  />
                </div>
              </div>
            </aside>
          </section>
          <section class="py5 tc">
            <!-- 已折叠121个对您无法服务的技师> -->
          </section>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: "recommend",
  components: {},
  data() {
    return {
      activityId: this.$route.query.activityId,
      list: [{}, {}],
      cateLogList: [],
      geoFlag: false,
      lat: "",
      lon: "",
    };
  },
  methods: {
    // 改变tab
    changeTab(index, title) {
      this.getList({
        activityId: this.activityId, //1优选技师
        catalogFirstId: this.cateLogList[index].catalogFirstId,
      });
    },
    // 获取类目
    getCateLogList() {
      this.$axios({
        url: this.$requests.member.getExcellentTechCateLogList,
        method: "post",
        data: {},
      }).then((res) => {
        let wrapper = res.data || {};
        this.cateLogList =
          wrapper.data.concat([
            { catalogFirstId: 2, catalogFirstName: "鬼斧神工" },
            { catalogFirstId: 2, catalogFirstName: "鬼斧神工" },
            { catalogFirstId: 2, catalogFirstName: "鬼斧神工" },
            { catalogFirstId: 2, catalogFirstName: "鬼斧神工" },
          ]) || [];
        // 获取列表
        this.getList({
          activityId: this.activityId, //1优选技师
          catalogFirstId: this.cateLogList[0].catalogFirstId,
        });
      });
    },
    // 获取列表
    getList(params) {
      if (this.geoFlag) {
        params = Object.assign(params, {
          lat: this.lat,
          lon: this.lon,
        });
      }
      this.$axios({
        url: this.$requests.member.getExcellentTechList,
        method: "post",
        data: params,
      }).then((res) => {
        let wrapper = res.data || {};
        this.list = wrapper.data || [];
      });
    },
    toNumber(val) {
      return Number(val);
    },
  },
  mounted() {
    // 获取类目
    this.getCateLogList();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (curPos) => {
          // 成功
          this.geoFlag = true;
          this.lat = curPos.coords.latitude;
          this.lon = curPos.coords.longitude;
        },
        (err) => {
          this.geoFlag = false;
          this.$dialog.alert({
            title: "错误提示",
            message: "地理位置获取失败",
          });
        }
      );
    } else {
      this.$dialog.alert({
        title: "错误提示",
        message: "您的浏览器不支持地理位置",
      });
    }
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  background: #efa1e0;
}
.lump {
  border-radius: 5px;
  padding: 10px;
  background: #fff;
}
.iconImg {
  width: 80px;
  height: 80px;
}
.price-lump {
  .left,
  .right {
    background: rgba(212, 90, 240, 1);
    line-height: 30px;
    height: 30px;
  }
  .left {
    width: 120px;
    border-radius: 5px 0 0 5px;
  }
  img {
    height: 30px;
  }
}
.tips {
  margin-right: 5px;
  padding: 2px 10px;
  color: rgba(211, 79, 243, 1);
  border-radius: 3px;
  border: 1px solid rgba(211, 79, 243, 1);
}
.appoint {
  &-left {
    padding: 2px 5px;
    color: #fff;
    background: rgba(62, 36, 36, 1);
    border: 1px solid rgba(62, 36, 36, 1);
    border-radius: 5px 0 0 0;
  }
  &-right {
    padding: 2px 5px;
    color: rgba(62, 36, 36, 1);
    border: 1px solid rgba(62, 36, 36, 1);
    border-radius: 0 0 5px 0;
  }
}
.line {
  color: rgba(204, 204, 204, 0.5);
}
// 修改vant样式
/deep/.van-tabs {
  background: rgba(239, 161, 224, 1);
  .van-tabs__nav {
    background: transparent;
  }
  .van-tab {
    padding: 0 5px;
    span {
      display: block;
      padding: 0 10px;
      height: 36px;
      line-height: 36px;
      border-radius: 5px;
      background: #fff;
    }
  }
  .van-tab--active {
    span {
      color: #fff;
      background: linear-gradient(
        to bottom,
        rgba(211, 79, 243, 1),
        rgba(234, 133, 223, 1)
      );
    }
  }
  .van-tabs__line {
    display: none;
  }
}
</style>
